import GatsbyImage, { FluidObject } from "gatsby-image"
import React, { useState } from "react"

import Styles from "./Slider.module.css"

interface Items {
    id: number,
    imagePath: FluidObject|FluidObject[] | undefined,
    alt: string,
    title: string,
}

interface SliderProps<> {
    items: Items[];

}

const Slider: React.FC<SliderProps> = ({items}) => {
    const slideLengthPerc = 100 / items.length
    const [pos, setPos] = useState<number>(0)
    const [loc, setLoc] = useState<number>(1)

    function slideBack() {
        if (loc === 1) {
            setLoc(items.length)
            setPos(slideLengthPerc * (items.length -1))
        } else {
            setLoc(loc - 1)
            setPos(slideLengthPerc * (loc-2))
        }
    }

    function slide() {
        if (loc === items.length) {
            setLoc(1)
            setPos(0)
        } else {
            setLoc(loc + 1)
            setPos(slideLengthPerc * loc)
        }
    }

    return (
        <div className={Styles.slidescontainer}>
            <div
                className={Styles.slides}
                style={{
                    transform: "translate(-" + pos + "%)",
                    transition: "transform 2s",
                    width: items.length + "00%",
                }}
            >
                {items.map(item => (
                    <div
                        className={Styles.content}
                        id={"contentbox-" + item.id}
                        key={`contentbox-${item.id}`}
                    >
                        <GatsbyImage
                            fluid={item.imagePath}
                            title={item.title}
                            alt={item.alt}
                            loading="lazy"
                            style={{ position: "unset" }}
                            className={Styles.gatsbyImage}
                        />
                    </div>
                ))}
            </div>
            <button className={`${Styles.slideButton} ${Styles.slideButtonBack}`} onClick={slideBack}><span className="align-text-top">&lsaquo;</span></button>
            <button className={`${Styles.slideButton} ${Styles.slideButtonNext}`} onClick={slide}><span className="align-text-top">&rsaquo;</span></button>
        </div>
    )

}

export default Slider
