import React, { ComponentProps } from "react"

import Style from "./TextImage.module.css"
import Section from "../Section/Section"
import OverlappingPolaroids from "../OverlappingPolaroids/OverlappingPolaroids"


type TextImageProps = {
    headline: string
    textOrder?: string
}&ComponentProps<typeof OverlappingPolaroids>
const TextImage: React.FC<TextImageProps> = ({ textOrder, headline, children, ...props }) => {
    return (
        <Section className="bg-white">
            <div className={Style.textImage}>
                <div
                    className={`order-last w-full lg:w-1/2 p-0 ${
                        Style.text
                    } md:${textOrder} ${
                        textOrder === "order-last" ? "mt-6 lg:mt-0 lg:pl-5" : "mb-6 lg:mb-0 lg:pr-5"
                    }`}
                >
                    <div className="w-full">
                        <h2 className="mt-6 md:pb-10">{headline}</h2>
                    </div>
                    {children}
                </div>
                <div className="w-full lg:w-1/2 p-0 max-w-lg">
                    <OverlappingPolaroids {...props} />
                </div>
            </div>
        </Section>
    )
}

export default TextImage
