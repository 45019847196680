import React from "react"
import GatsbyImage from "gatsby-image"

import Style from "./ImageDivider.module.css"
import { FluidImageFile } from "../.."

interface ImageDividerProps<> {
    image: FluidImageFile
    alt: string
    title: string
}
const ImageDivider: React.FC<ImageDividerProps> = ({
    image,
    alt,
    title
}) => {
    return (
        <section className={Style.imgoverlay}>
            <GatsbyImage
                fluid={image}
                alt={alt}
                title={title}
                loading="lazy"
                style={{ position: "unset" }}
                className={Style.gatsbyImage}
            />
        </section>
    )
}

export default ImageDivider
