import React from "react"

import Styles from "./Infos.module.css"
import Section from "../Section/Section"

const Infos: React.FC = () => {
    return (
        <Section className="bg-gray-300" sectionsmall={true}>
            <div className="flex flex-col sm:flex-row">
                <div className={Styles.info}>
                    <span className="font-semibold text-lg">Anschrift</span>
                    <ul>
                        <li>Lindsey &amp; friends</li>
                        <li>Hundeträume</li>
                        <li className="street">Blücherplatz 4</li>
                        <li className="city pb-p">32139 Spenge</li>
                    </ul>
                </div>
                <div className={`${Styles.info} pt-3`}>
                    <span className="font-semibold text-lg">
                        Öffnungszeiten
                    </span>
                    <ul>
                        <li className="mt-2">Montag:</li>
                        <li>10:00 bis 13:00 Uhr</li>
                        <li>Dienstag: geschlossen</li>
                        <li>
                            Mittwoch bis Freitag:
                        </li>
                        <li>10:30 bis 16:30 Uhr</li>
                        <li className="mt-2">Samstag:</li>
                        <li>10:00 bis 13:00 Uhr</li>
                    </ul>
                </div>
                <div className={`${Styles.info} pt-3`}>
                    <span className="font-semibold text-lg">Kontakt</span>
                    <ul>
                        <li className="pb-p">
                            <a href="tel:+495225879288" className="no-wrap textlink">
                                0&nbsp;52&nbsp;25&nbsp;&ndash;&nbsp;87&nbsp;92&nbsp;88
                            </a>
                        </li>
                        <li>
                            <a href="mailto:info@lindseyandfriends.de" className="textlink">
                                info@lindseyandfriends.de
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </Section>
    )
}

export default Infos
