import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

import Styles from "./News.module.css"

const News: React.FC = () => {
    const { allSanityPost } = useStaticQuery(graphql`
        query NewsQuery {
            allSanityPost(
                sort: { fields: publishedAt, order: DESC }
                limit: 1
            ) {
                edges {
                    node {
                        _id
                        title
                        _rawBody
                        publishedAt
                    }
                }
            }
        }
    `)

    const sanityPost = allSanityPost?.edges[0]?.node

    if (!sanityPost) {
        return null;
    }

    const date = new Date(sanityPost.publishedAt)
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)

    return (
        <article className={Styles.article}>
            {!!sanityPost.mainImage &&
              <div
                className={Styles.bgImage}
                style={{
                    backgroundImage: `url("${sanityPost.mainImage.publicURL}")`,
                }}
              />
            }
            <div className={Styles.textWrapper}>
                <div className="w-full">
                    <h3>{sanityPost.title}</h3>
                    <div className="py-8">
                    <BlockContent
                            // Array of portable text blocks
                            blocks={sanityPost._rawBody}
                        />
                    </div>
                    <p>
                        Verfasst am: {day}.{month}.{date.getFullYear()}
                    </p>
                </div>
            </div>
        </article>
    )
}

export default News

