import React, { useState } from "react"
import { FluidImageFile } from "../.."
import Polaroid from "../Polaroid/Polaroid"

import Styles from "./Products.module.css"

interface Items {
    id: string
    imagePath: FluidImageFile | undefined
    alt: string
    title: string
    headline: string
    description: string
}

interface ProductsProps<> {
    items: Items[]
    sectionHeadline: string
    headtext: string
}

const Products: React.FC<ProductsProps> = ({ items, sectionHeadline, headtext }) => {
    const [imageState, setImage] = useState<String>("futter")

    return (
        <div className="flex flex-wrap mb-8 lg:mb-4 xl:mb-6">
            <div className="order-last w-full lg:w-3/5 p-0 pl-5 md:order-lastmt-6 lg:mt-0 lg:pl-5">
                <div className="w-full">
                    <h2 className="mt-6 md:pb-10">{sectionHeadline}</h2>
                </div>
                <p>{headtext}</p>
                <div className="pt-5">
                    {items.map(item => (
                        <p
                            key={`product-${item.id}`}
                            className={Styles.listitem}
                        >
                            <span
                                className={`${
                                    imageState === item.id ? "hidden" : ""
                                }`}
                            >
                                &bull;
                            </span>
                            <span
                                className={`${
                                    imageState === item.id ? "" : "hidden"
                                } text-gold`}
                            >
                                &#9656;
                            </span>
                            <button
                                onClick={() => setImage(item.id)}
                                className={`${
                                    imageState === item.id ? "text-gold" : ""
                                }`}
                            >
                                &nbsp;{item.headline}
                            </button>
                        </p>
                    ))}
                </div>
            </div>
            {items.map((item, index) => (
                <div
                    className={`${
                        imageState === item.id ? "" : "hidden"
                    } w-full lg:w-2/5 p-0 max-w-lg m-auto`}
                    key={`productimage-${item.id}`}
                >
                    <Polaroid
                        image={item.imagePath}
                        title={item.title}
                        alt={item.alt}
                        className={`${
                            index % 2 === 0 ? "rotate-5" : "rotate5"
                        } m-auto`}
                    />
                    <p className="mt-10 bg-gray-300 p-5 slideText">
                        {item.description}
                    </p>
                </div>
            ))}
        </div>
    )
}

export default Products
