import React from "react"

import Styles from "./OverlappingPolaroids.module.css"
import { FluidImageFile } from "../.."
import Polaroid from "../Polaroid/Polaroid"

interface OverlappingPolaroidsProps<> {
    className?: string
    subtitle?: string
    titleback: string
    altback: string
    imageback: FluidImageFile
    titlefront: string
    altfront: string
    imagefront: FluidImageFile
}
const OverlappingPolaroids: React.FC<OverlappingPolaroidsProps> = ({
    className,
    titleback,
    altback,
    imageback,
    titlefront,
    altfront,
    imagefront
}) => {
    return (
        <div className={Styles.polaroidWrapper}>
            <Polaroid image={imageback} alt={altback} title={titleback}/>
            <Polaroid image={imagefront} alt={altfront} title={titlefront} className={Styles.imagefront}/>
        </div>
    )
}

export default OverlappingPolaroids
