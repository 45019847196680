import React, { ReactNode } from "react"

import Style from "./HeaderImage.module.css"
import GatsbyImage from "gatsby-image"
import { FluidImageFile } from "../.."

interface HeaderImageProps<> {
    headline: ReactNode
    background: FluidImageFile
}
const HeaderImage: React.FC<HeaderImageProps> = ({
    headline,
    background
}) => {
    return (
        <section
            className={Style.imgoverlay}
        >
            <GatsbyImage
                fluid={background}
                alt=""
                title=""
                loading="lazy"
                style={{ position: "unset" }}
                className={Style.gatsbyImage}
            />
            <div className={Style.headline} id="anchor">
                <div className="w-full md:w-11/12 p-0 m-auto">
                    <h1 className="text-white">{headline}</h1>
                </div>
            </div>
        </section>
    )
}

export default HeaderImage
