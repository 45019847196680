import React from "react"

import Styles from "./Polaroid.module.css"
import GatsbyImage from "gatsby-image"
import { FluidImageFile } from "../.."

interface PolaroidProps<> {
    className?: string
    subtitle?: string
    title: string
    alt: string
    image: FluidImageFile
}
const Polaroid: React.FC<PolaroidProps> = ({
    className,
    subtitle,
    image,
    title,
    alt
}) => {
    return (
        <div className={`${Styles.card} ${className}`}>
            <GatsbyImage fluid={image} title={title} alt={alt} loading="lazy"/>
            <p className={Styles.text}>{subtitle}</p>
        </div>
    )
}

export default Polaroid
